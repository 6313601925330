import React, { useState } from 'react';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { useLocation, useNavigate } from 'react-router';
import { MUSIC_PLATFORMS } from '../../../../constants/constants';
import MusicPlatform from '../../../../components/AddSection/Music/MusicPlatform';
import { useDispatch } from 'react-redux';
import { clearAlerts } from '../../../../store/alert/alertSlice';
import { addMusicToSectionThunk } from '../../../../store/addsection/addsectionThunk';
import DizeeButton from '../../../../components/DizeeComponent/DizeeButton';

export default function MusicPlatformPage() {
    const token = localStorage.getItem('dizeeToken');
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { musicItem } = location.state;


    const handleTogglePlatform = (platform) => {
        setSelectedPlatforms(prevSelected =>
            prevSelected.includes(platform)
                ? prevSelected?.filter(item => item !== platform)
                : [...prevSelected, platform]
        );
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Select a platform for preview</p>
                    <div className='flex gap-x-4'>
                        <DizeeButton onClick={() => navigate('/add-section/music-playback', { state: { musicItem: musicItem, selectedPlatforms } })} label={'Next'} />
                        <DizeeButton onClick={() => navigate(-1)} label={'Go back'} />
                    </div>
                </div>
                {
                    MUSIC_PLATFORMS.map((music, musicIndex) => (
                        musicItem.links?.filter((i) => i.source === music.value).map((item, itemIndex) => {
                            return (
                                <MusicPlatform
                                    txt={music.label}
                                    key={`${musicIndex}-${musicIndex}`} // Ensure unique keys for each music
                                    selected={selectedPlatforms.includes(music.value)}
                                    onSelect={() => handleTogglePlatform(music.value)}
                                />
                            );
                        })
                    ))
                }


            </div>
        </LayoutHeader>
    );
}
