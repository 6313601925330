import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import LayoutHeader from "../../../../layout/LayoutHeader";
import DizeeButton from "../../../../components/DizeeComponent/DizeeButton";
import DizeeSelect from "../../../../components/DizeeComponent/DizeeSelect";
import DizeeModal from "../../../../components/DizeeComponent/DizeeModal";
import {
  addMusicToSectionThunk,
  addVideoToSectionThunk,
  addEventToSectionThunk,
} from "../../../../store/addsection/addsectionThunk";
import { add_video_message_thunk } from "../../../../store/user/userThunk";
import { clearEvent } from "../../../../store/eventData/eventdataSlice";
import { clearAlerts } from "../../../../store/alert/alertSlice";
import { Calendar } from "@nayojs/react-datetime-picker";
import DizeeTimeInput from "../../../../components/DizeeComponent/DizeeTimeInput";

export default function PublishOption() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("dizeeToken");
  const [option, setOption] = useState("draft");
  const [showCalendar, setShowCalendar] = useState(false);
  const [time, setTime] = useState("");
  const [pm, setPM] = useState(false);
  const [date, setDate] = useState("");

  const confirm = () => {
    const type = findSection(location.pathname);
    if (option === "draft" || option === "publish") {
      handleAdd({ value: option }, type);
    } else if (option === "schedule") {
      setShowCalendar(true);
    }
  };
  const findSection = (path) => {
    if (String(path).indexOf("/add-section/video-message/publish") > -1)
      return "video-message";
    else if (String(path).indexOf("/add-section/music/publish") > -1)
      return "music-search";
    else if (String(path).indexOf("/add-section/music/manual/publish") > -1)
      return "music-manual";
    else if (String(path).indexOf("/add-section/video/publish") > -1)
      return "video";
    else if (String(path).indexOf("/add-section/event/publish") > -1)
      return "add-event";
  };
  const handleAdd = (publish, type) => {
    let payload;

    dispatch(clearAlerts());

    if (type === "video-message") {
      payload = {
        ...location.state,
        publish,
      };
      dispatch(add_video_message_thunk({ videoMessage: payload, token })).then(
        (res) => {
          if (res.payload) {
            navigate("/add-section/video-message");
          }
        }
      );
    } else if (type === "music-search") {
      const selectedLinks =
        location.state.musicItem?.links?.filter((link) =>
          location.state.selectedPlatforms.includes(link.source)
        ) || [];
      payload = {
        type: 0,
        id: location.state.musicItem?.songstats_track_id,
        avatar: location.state.musicItem?.avatar,
        title: location.state.musicItem?.title,
        artist: location.state.musicItem?.artists[0].name,
        links: selectedLinks,
        publish,
        playback: location.state.playback,
      };

      dispatch(addMusicToSectionThunk({ token: token, payload: payload })).then(
        (res) => {
          if (res.payload.status === 200) {
            navigate("/add-section/search-for-release");
          }
        }
      );
    } else if (type === "music-manual") {
        console.log(`location.state::`, location.state);
      if (Array.isArray(location.state._payload)) {
        // payload = {
        //   ...location.state,
        //   publish,
        // };
        payload = [];
        location.state._payload.map((item, key) => {
            let tmp = {
                ...item,
                publish
            }
            payload.push(tmp);
        })
        console.log(`payload first::`, payload);
        dispatch(
          addMusicToSectionThunk({ token: token, payload: payload })
        ).then((res) => {
          if (res.payload.status === 200) {
            navigate("/add-section/custom-music-link");
          }
        });
      } else {
        payload = {
          ...location.state,
          publish,
        };
        console.log(`payload second::`, payload);
        dispatch(
          addMusicToSectionThunk({ token: token, payload: payload })
        ).then((res) => {
          if (res.payload.status === 200) {
            navigate("/add-section/custom-music-link");
          }
        });
      }
    } else if (type === "video") {
      dispatch(
        addVideoToSectionThunk({
          token: token,
          payload: { ...location.state, publish },
        })
      ).then((res) => {
        if (res.payload.status === 200) {
          navigate("/add-section/add-video");
        }
      });
    } else if (type === "add-event") {
      dispatch(clearEvent());
      dispatch(
        addEventToSectionThunk({
          token: token,
          payload: { ...location.state.payload, publish },
          previousUrl: location.state.previousUrl,
        })
      ).then(() => {
        navigate("/add-section/add-event");
      });
    }
  };
  const selectDateHandler = (date) => {
    setDate(
      `${new Date(date).getFullYear()}-${new Date(date).getMonth()}-${new Date(
        date
      ).getDate()}`
    );
  };
  const onTimeChangeHandler = (val) => {
    if (val.length === 5) {
      setTime(val);
    }
  };
  const onSaveSchedule = () => {
    if (date.length < 1 && time.length < 5) {
      return;
    }
    handleAdd(
      { value: "schedule", time: `${date} ${time} ${pm ? "pm" : "am"}` },
      findSection(location.pathname)
    );
  };

  return (
    <LayoutHeader>
      <div className="w-[390px] h-[90vh] bg-black flex flex-col  items-center relative">
        <div className="px-4 my-[50px] flex w-full justify-between">
          <p className="text-white">Select publish option</p>
          <div className="flex gap-x-4">
            <DizeeButton onClick={() => confirm()} label={"Done"} />
            <DizeeButton onClick={() => navigate(-1)} label={"Go back"} />
          </div>
        </div>
        {
          <div className="w-full">
            <DizeeSelect
              selected={option === "draft"}
              txt={"Draft"}
              onSelect={() => setOption("draft")}
              checkmark={true}
            />
            <DizeeSelect
              selected={option === "schedule"}
              txt={"Schedule"}
              onSelect={() => setOption("schedule")}
              checkmark={true}
            />
            <DizeeSelect
              selected={option === "publish"}
              txt={"Publish"}
              onSelect={() => setOption("publish")}
              checkmark={true}
            />
          </div>
        }
      </div>
      {showCalendar && (
        <DizeeModal
          isOverlayVisible={showCalendar}
          setOverlayVisible={setShowCalendar}
        >
          <div>
            <Calendar
              selectDateHandler={selectDateHandler}
              calendarStyles={{
                containerStyles: { backgroundColor: "#000" },
                dateStyles: { color: "#fff" },
                selectedDateStyles: { backgroundColor: "#000", color: "#ff2" },
                headerStyles: { background: "#000" },
                headerTitleTextStyles: { color: "#fff" },
                headerTitleStyles: { color: "#fff" },
              }}
            />
            <div className="flex">
              <DizeeTimeInput
                name="example"
                initTime="11:12"
                className="s-input -time dizee-input text-white pl-[10px] w-[50px]"
                mountFocus="true"
                onTimeChange={onTimeChangeHandler}
              />
              <div className="flex gap-x-2">
                <div className="w-[50px]">
                  <DizeeSelect
                    selected={!pm}
                    txt={"AM"}
                    onSelect={() => setPM(false)}
                    checkmark={true}
                  />
                </div>
                <div>
                  <DizeeSelect
                    selected={pm}
                    txt={"PM"}
                    onSelect={() => setPM(true)}
                    checkmark={true}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-x-3 mt-[20px] pl-[10px]">
              <DizeeButton label={"Confirm"} onClick={onSaveSchedule} />
              <DizeeButton
                label={"Go back"}
                onClick={() => setShowCalendar(false)}
              />
            </div>
          </div>
        </DizeeModal>
      )}
    </LayoutHeader>
  );
}
